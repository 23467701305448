<template>
    <div class="shop_order_menu">
        <h2 class="title">个人中心</h2>
        <div class="menu">
            <div class="head">
                <h1 class="name">订单管理</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '1' ? '' : $router.push('/shop/order')" :class="type === '1' || type === '11' ? 'on' : ''">我的订单</a>
<!--                <a href="javascript:void(0)" @click="type === '9' ? '' : $router.push('/shop/refund')" :class="type === '9' ? 'on' : ''">退款维权</a>-->
            </div>
        </div>

        <div class="menu">
            <div class="head">
                <h1 class="name">收藏夹</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '8' ? '' : $router.push('/shop/keep')" :class="type === '8' ? 'on' : ''">我的收藏</a>
            </div>
        </div>

        <div class="menu">
            <div class="head">
                <h1 class="name">地址管理</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '2' ? '' : $router.push('/shop/address')" :class="type === '2' ? 'on' : ''">我的地址</a>
            </div>
        </div>

        <div class="menu">
            <div class="head">
                <h1 class="name">个人资料</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '3' ? '' : $router.push('/shop/account')" :class="type === '3' || type === '31' || type === '32' || type === '33' ? 'on' : ''">账户中心</a>
                <a href="javascript:void(0)" @click="type === '4' ? '' : $router.push('/shop/realName')" :class="type === '4' || type === '41' ? 'on' : ''">实名认证</a>
            </div>
        </div>

        <div class="menu">
            <div class="head">
                <h1 class="name">供求信息</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '6' ? '' : $router.push('/shop/supplyBuying/quotation')" :class="type === '6' || type === '6-1' ? 'on' : ''">我的报价单</a>
                <a href="javascript:void(0)" @click="type === '7' ? '' : $router.push('/shop/supplyBuying/attention')" :class="type === '7' ? 'on' : ''">关注的商家</a>
            </div>
        </div>

        <div class="menu">
            <div class="head">
                <h1 class="name">合同管理</h1>
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="content">
                <a href="javascript:void(0)" @click="type === '5' ? '' : $router.push('/shop/contract')" :class="type === '5' ? 'on' : ''">我的合同</a>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "userMenu",
    props: {
      type: {
        type: String,
        default: ''
      }
    },
    data () {
      return {}
    },
    methods: {
    },
    components: {}
  }
</script>
